<template lang="html">
  <div id="home-page">

    <section class="banners-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bpcInx) in banners.pc" :key="'bpcInx-'+bpcInx">
          <router-link class="swiper-element" to="/contacto">
            <img :src="b.imageUrl">
          </router-link>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bmbInx) in banners.mobile" :key="'bmbInx-'+bmbInx">
          <router-link class="swiper-element" to="/contacto">
            <img :src="b.imageUrl">
          </router-link>
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </section>

    <section class="extra-banners-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-md-6 col-lg-4 col-ebanner">
             <a class="box" v-scroll-to="'#plans-h-sections'">
              <div class="image">
                <img src="public/images/pages/home/ebanner-1.jpg">

                <div class="box-text">
                  <h5>Conoce nuestros rendimientos del</h5>
                  <h4>10-20% anual</h4>
                </div>
              </div>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 col-ebanner">
            <a class="box" v-scroll-to="'#presence-h-section'">
              <div class="image">
                <img src="public/images/pages/home/ebanner-2.jpg">

                <div class="box-text">
                  <h5>Construimos en el Sureste Mexicano</h5>
                </div>
              </div>
            </a>
          </div>

          <div class="col-md-6 col-lg-4 col-ebanner">
            <a class="box" v-scroll-to="'#numbers-h-section'">
              <div class="image">
                <img src="public/images/pages/home/ebanner-3.jpg">

                <div class="box-text">
                  <h5>Conoce nuestros números</h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="plans-sections" id="plans-h-sections">
      <div class="container">
        <h2 class="title">Elige el tipo de plan ideal</h2>

        <div class="row box-plans">
          <div class="col-md-6 col-xl-5 col-plan">
            <div class="box-plan">
              <div class="placed-backg b-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/plan-img-1.jpg)' }"></div>

              <div class="b-text">
                <h4 class="plan-name">P15</h4>
                <h4 class="plan-title">Inversión a 15 meses</h4>
                <p class="descr">
                  Los clientes invierten en este plazo y al final del mes 15 se les pagan sus rendimientos
                </p>

                <hr />

                <h5 class="mb-3 subtitle-1">Rendimiento entre el<br />18 al 25%</h5>
                <h4 class="time">15 Meses</h4>
                <!-- <p class="mt-3">
                  <a class="link-commissions" @click="openModalCommissions(1)">Ver comisiones*</a>
                </p> -->

                <hr />

                <p class="text-right">
                  <router-link class="link-contract" to="/contacto">Contratar <i class="far fa-chevron-right icon"></i></router-link>
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xl-5 col-plan">
            <div class="box-plan">
              <div class="placed-backg b-image" v-bind:style="{ backgroundImage: 'url(public/images/pages/home/plan-img-2.jpg)' }"></div>

              <div class="b-text">
                <h4 class="plan-name">PPM</h4>
                <h4 class="plan-title">Inversión de pago mensual</h4>
                <p class="descr">
                  Éste plan le permite al inversionista poder recibir un rendimiento mensual a plazos de 24, 36, 48 y 60 meses
                </p>

                <hr />

                <h5 class="mb-3 subtitle-1">Rendimiento entre el 10 al 19% anual</h5>
                <h4 class="time">24, 36, 48 y 60 meses</h4>
                <!-- <p class="mt-3">
                  <a class="link-commissions" @click="openModalCommissions(2)">Ver comisiones*</a>
                </p> -->

                <hr />

                <p class="text-right">
                  <router-link class="link-contract" to="/contacto">Contratar <i class="far fa-chevron-right icon"></i></router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="about-us-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-info">
            <h2 class="title">Conócenos</h2>

            <p class="p-lg">
              Somos una empresa regulada, especializada en CAPTACIÓN DE CAPITAL, ofrecemos a nuestros inversionistas atractivos rendimientos a través de desarrollos inmobiliarios.
            </p>
          </div>

          <div class="col-lg-5 offset-xl-1 col-carousel">
            <swiper class="swiper" :options="aboutOptions">
              <swiper-slide>
                <div class="box-element-info">
                  <div class="b-images">
                    <img src="public/images/pages/home/about-us-img-1.png">
                  </div>

                  <div class="b-text">
                    <h4>
                      Arquitectos, ingenieros, abogados, fiscalistas, son parte del equipo de profesionales que nos respaldan.
                    </h4>

                    <p class="mt-4 text-right">
                      <router-link class="link-more" to="/equipo">Conócenos <i class="fas fa-arrow-alt-circle-right icon"></i></router-link>
                    </p>
                  </div>
                </div>
              </swiper-slide>

              <!-- <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div> -->
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <FaqsComponent></FaqsComponent>

    <section class="join-us-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-image">
            <img src="public/images/pages/home/join-us.png">
          </div>

          <div class="col-lg-5 col-text">
            <h2 class="title">Impulsa tu práctica de asesoría financiera</h2>

            <p class="p-lg">
              PiedrAngular extiende su oferta de servicios e infraestructura a los asesores financieros afiliados para que puedan emprender su propia práctica financiera y así ayudar a sus clientes a alcanzar sus objetivos financieros.
            </p>

            <p class="mt-4">
              <router-link class="btn btn-s1" to="/contacto">Quiero formar parte</router-link>
              <router-link class="btn btn-s2 c1" to="/equipo"><span>Conoce al equipo</span></router-link>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="why-piedraangular-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 mx-auto">
            <h2 class="title">Por qué invertir con PiedrAngular en un fideicomiso bancario?</h2>
          </div>
        </div>

        <b-collapse id="collapse-1" class="box-info" v-model="visibleWhy">
          <b-card>
            <p>
              Los FIDEICOMISOS ofrecen una forma segura y confiable de manejar grandes sumas de dinero y activos. Los bancos
              actúan como fiduciarios y proporcionan servicios especializados para crear, administrar y
              ejecutar fideicomisos.
            </p>
            <br />
            <p>
              Un fideicomiso bancario en México es un instrumento financiero en el cual una persona o entidad, llamada
              fideicomitente, transfiere la propiedad de ciertos bienes o recursos a un banco u otra institución, conocida como
              fiduciario. Este fiduciario se encarga de administrar y utilizar esos bienes de acuerdo con las instrucciones establecidas
              en el contrato de fideicomiso.
            </p>
            <br />
            <p>
              Los fideicomisos bancarios en México están regulados por la Ley de Instituciones de
              Crédito y la Comisión Nacional Bancaria y de Valores. Estos instrumentos se utilizan para diversos fines, como la
              administración de patrimonios, la inversión en proyectos inmobiliarios o la garantía de cumplimiento de obligaciones.
            </p>
            <br />
            <p>
              Es importante destacar que los fideicomisos bancarios son irrevocables, lo que significa que una vez constituidos, no
              pueden modificarse o revocarse. Además, el fideicomiso cuenta con un patrimonio autónomo, separado del
              patrimonio del fiduciario y del fideicomitente, lo que brinda seguridad y protección a los bienes involucrados.
            </p>
            <br />
            <p>
              En resumen, el fideicomiso bancario en México es una herramienta versátil y segura para la administración de
              bienes y recursos, con un
              marco legal establecido que garantiza su correcto funcionamiento y protege a las partes involucradas.
            </p>
          </b-card>
        </b-collapse>

        <div class="box-btn-toggle">
          <a class="btn-toggle" @click="visibleWhy = !visibleWhy">
            <i class="fal fa-chevron-circle-down" v-if="!visibleWhy"></i>
            <i class="fal fa-chevron-circle-up" v-else></i>
          </a>
        </div>
      </div>
    </section>

    <b-modal modal-class="modal-commissions" ref="modal-commissions" hide-footer centered title="">
      <div v-if="infoCommissions == 1">
        <h6 class="mb-2 title">Comisión plan 15 meses</h6>
        <p>
          La comisión que ofrece nuestra empresa es del 1% sobre monto invertido del cliente, esta comisión será pagada al inicio del plan y cada vez que el cliente re invierta en nuestro producto al termino de los 15 meses.
        </p>
      </div>

      <div v-if="infoCommissions == 2">
        <h6 class="mb-2 title">Comisión plan de pago mensual</h6>
        <p>
          En este plan la comision depende de la temporalidad que elija cada cliente partiendo desde el 3% a 24 meses, 4.5 a 36 meses, 6% a 48 meses y del 7.5% a 60 meses. Este plan te paga en dos partes, la primera al inicio del plan y la segunda al mes 13, digamos el 50 y 50% de la comisión.
        </p>
      </div>
    </b-modal>

  </div>
</template>

<script>
import FaqsComponent from './faqs/faqs-section-component.vue';
export default {
  components: {
    FaqsComponent,
  },

  data() {
    return {
      banners: {
        pc: [
          { imageUrl: 'public/images/pages/home/banner-1.jpg' },
          { imageUrl: 'public/images/pages/home/banner-2.jpg' },
          { imageUrl: 'public/images/pages/home/banner-3.jpg' },
          { imageUrl: 'public/images/pages/home/banner-4.jpg' },
          { imageUrl: 'public/images/pages/home/banner-5.jpg' },
        ],
        mobile: [
          { imageUrl: 'public/images/pages/home/banner-1-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-2-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-3-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-4-m.jpg' },
          { imageUrl: 'public/images/pages/home/banner-5-m.jpg' },
        ],
      },

      infoCommissions: 1,
      visibleWhy: false,

      // == Carousels options ==
      bannersOptions: {
        loop: true,
        speed: 750,
        effect: 'fade',

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      },

      aboutOptions: {
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // }
      },
      // == ==
    }
  },

  methods: {
    openModalCommissions(infoCo) {
      this.infoCommissions = infoCo;
      this.$refs['modal-commissions'].show();
    }
  },

  mounted() {
  }
}
</script>
