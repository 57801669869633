<template lang="html">
  <div id="projects-page">

    <section class="main-section">
      <div class="container oversized-container">
        <div class="box-top">
          <h1 class="page-title">Proyectos</h1>

          <div class="d-block mt-2 text-center">
            <router-link class="btn my-1 my-sm-2 btn-s1 disabled" to="/proyectos">Chelem Yucatán V1</router-link>
            <a class="btn my-1 my-sm-2 btn-s1" target="_blank" href="/public/pdfs/BROCHURE-V2.pdf">Chuburná Yucatán V2</a>
            <router-link class="btn my-1 my-sm-2 btn-s1" to="/proyectos/chelem-v3">Chelem Yucatán V3</router-link>
            <router-link class="btn my-1 my-sm-2 btn-s1" to="/proximamente">Altozano</router-link>
            <!-- <router-link class="btn my-1 my-sm-2 btn-s1" to="/proximamente">Grupo Rhada</router-link> -->
            <router-link class="btn my-1 my-sm-2 btn-s1" to="/proximamente">Departamentos Residenciales</router-link>

            <hr class="mt-4" />
            <div class="d-block mt-3 box-proyect-info">
              <h5 class="project-name">Chelem Yucatán V1</h5>
              <h6 class="mt-3 mb-1 f-w-600">DESCARGAR BROCHURE</h6>

              <a class="btn my-1 my-sm-2 btn-s2 c2" target="_blank" href="public/pdfs/BROCHURE-V1.pdf"><span><i class="fas fa-download"></i> Brochure Chelem Yucatán V1</span></a>
              <a class="btn my-1 my-sm-2 btn-s2 c2" target="_blank" href="public/pdfs/Presentacion_Proyectos_invrengar.pdf"><span><i class="fas fa-download"></i> Catalogo General</span></a>
            </div>
          </div>

          <div class="no-gutters box-gallery">
            <div class="col-6 col-md-6 col-lg-4 col-xl-3 col-image" v-for="(image, imageIndex) in items" :key="imageIndex">
              <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+image+')' }" @click="index = imageIndex">
                <img src="public/images/shared/empty.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <CoolLightBox
      :items="items"
      :index="index"
      :effect="'fade'"
      @close="index = null">
    </CoolLightBox>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      items: [
        'public/images/pages/projects/project-1/image-1.jpg',
        'public/images/pages/projects/project-1/image-2.jpg',
        'public/images/pages/projects/project-1/image-3.jpg',
        // 'public/images/pages/projects/project-1/image-4.jpg',
        'public/images/pages/projects/project-1/image-5.jpg',
        'public/images/pages/projects/project-1/image-6.jpg',
        'public/images/pages/projects/project-1/image-7.jpg',
        'public/images/pages/projects/project-1/image-8.jpg',
        'public/images/pages/projects/project-1/image-9.jpg',
        'public/images/pages/projects/project-1/image-10.jpg',
        'public/images/pages/projects/project-1/image-11.jpg',
        'public/images/pages/projects/project-1/image-12.jpg',
        'public/images/pages/projects/project-1/image-13.jpg',
        'public/images/pages/projects/project-1/image-14.jpg',
        'public/images/pages/projects/project-1/image-15.jpg',
        'public/images/pages/projects/project-1/image-16.jpg',
        'public/images/pages/projects/project-1/image-17.jpg',
        'public/images/pages/projects/project-1/image-18.jpg',
        'public/images/pages/projects/project-1/image-19.jpg',
        'public/images/pages/projects/project-1/image-20.jpg',
        'public/images/pages/projects/project-1/image-21.jpg',
        'public/images/pages/projects/project-1/image-22.jpg',
        'public/images/pages/projects/project-1/image-23.jpg',
        'public/images/pages/projects/project-1/image-24.jpg',
        // 'public/images/pages/projects/project-1/image-25.jpg',
        'public/images/pages/projects/project-1/image-26.jpg',
      ],
      index: null,
    }
  },
}
</script>
