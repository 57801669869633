import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', name: 'homePage', component: require(page+'home.vue').default, meta:{title:"Home"}},
      { path: '/empresa', component: require(page+'about-us/index.vue').default, meta:{title:"Empresa"}},
      { path: '/garantias-y-normas', component: require(page+'guarantees/index.vue').default, meta:{title:"Garantías y normas"}},
      { path: '/calculadora', component: require(page+'calculator/index.vue').default, meta:{title:"Calculadora"}},
      { path: '/faq', component: require(page+'faqs/index.vue').default, meta:{title:"F.A.Q."}},
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},
      { path: '/equipo', component: require(page+'team/index.vue').default, meta:{title:"Conoce al equipo"}},

      { path: '/proyectos', component: require(page+'projects/index.vue').default, meta:{title:"Proyectos"}},
      { path: '/proyectos/chelem-v3', component: require(page+'projects/chelem-v3.vue').default, meta:{title:"Proyecto Chelem Yucatán V3"}},

      { path: '/proximamente', component: require(page+'text-pages/proximamente.vue').default, meta:{title:"Proximamente"}},

      { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y condiciones"} },
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"} },
	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Piedran Angular", filter: (title)=>{ return title+" - Piedran Angular"; } }
);

// export {routes};
export default MyRouter;
